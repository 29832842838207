import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar flag={true} />
            <div className="py-10 px-5 lg:px-32">
                <div className='text-center font-bold text-4xl lg:text-5xl'>
                    <h1>Privacy Policy</h1>
                    <h1>&</h1>
                    <h1>Terms Of Service</h1>
                </div>
                <div className="py-5">
                    <h1 className='text-xl font-semibold py-5'>1. Introduction</h1>
                    <p className='text-[16px]'> Climax Gaming Studios Inc. ("Climax Gaming Studios," "we," "us," or "our") is committed to protecting the privacy of our users ("user" or "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard information when you access or use our mobile games, websites, and other online services (collectively, the "Services"). Please read this Privacy Policy carefully to understand our practices regarding your information and how we will treat it.</p>
                    <h1 className='text-xl font-semibold py-5'>2. Information We Collect</h1>
                    <p className='text-[16px]'>We do not directly collect any personal information from you when you use our Services. We do not require you to create an account or provide any personal details to access our games or services.</p>
                    <p className='text-[16px]'>The data collected by third parties is non-personally identifiable whenever possible, which can reassure users about their privacy.</p>
                    <p className='text-[16px]'>While we do not collect personal information directly, we do work with trusted third-party analytics and advertising partners who may collect certain information automatically as you interact with our Services. This information may include:</p>
                    <div className="p-5">
                        <p className='text-[16px]'><strong>a)</strong> Device Information: Data about the device you use to access our Services, such as device type, operating system, unique device identifiers, IP address, mobile network information, and information about the device's interaction with our Services.</p>
                        <p className='text-[16px]'><strong>b)</strong> Usage Information: Data about how you interact with and use our Services, including gameplay data, session lengths, levels reached, in-game purchases, and other usage metrics.</p>
                        <p className='text-[16px]'><strong>c)</strong> Location Information: Approximate geolocation data based on your IP address or mobile network information to provide you with location-based services or content.</p>
                        <p className='text-[16px]'><strong>d)</strong> Advertising Data: Information about your interactions with advertisements displayed within our Services, including the type of advertisements viewed and whether you interacted with them.
                        </p>
                    </div>
                    <h1 className='text-xl font-semibold py-5'>3. How We Use Your Information</h1>
                    <p className='text-[16px]'>
                        We and our third-party partners use the information collected for various purposes, including: <br />
                        To improve and optimize our Services by analyzing usage trends, tracking performance metrics, and identifying areas for enhancement or bug fixes.<br />
                        To deliver customized content and personalized gaming experiences tailored to your preferences and gameplay behaviors.<br />
                        To serve targeted advertisements within our Services that may be more relevant to your interests and preferences based on your device information and interactions with our games.<br />
                        To comply with legal obligations, resolve disputes, prevent fraud or abuse, and enforce our Terms of Service.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>4. Third-Party Analytics and Advertising Partners</h1>
                    <p className='text-[16px]'>
                        We work with trusted third-party analytics and advertising partners to help us understand how our Services are being used, to provide targeted advertising, and to assist with other analytics and marketing efforts. These partners may collect and process information about your use of our Services as described above. <br />
                        Please note that these third-party partners may have their own privacy policies governing their collection, use, and disclosure of information. We encourage you to review their privacy policies to understand their practices.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>5. Advertising</h1>
                    <p className='text-[16px]'>
                        We display advertisements within our games through third-party advertising partners. These advertisements may be targeted based on information collected about your device, gameplay data, and interactions with the advertisements. <br />
                        Our advertising partners may use cookies, web beacons, and other tracking technologies to collect information about your interactions with the advertisements and your device. This information may be used to provide you with more relevant advertising content and to measure the effectiveness of advertising campaigns. Please note that these tracking technologies are used exclusively by our third-party partners and not directly by us. <br />
                        We encourage you to review the privacy policies of our advertising partners to understand how they collect and use your information for advertising purposes. You may have options to control or block such tracking technologies through your device settings or by opting out directly on their websites.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>6. Children's Privacy</h1>
                    <p className='text-[16px]'>
                        Our Services are not directed towards children under the age of 13, and we do not knowingly collect personal information from children under 13. <br />
                        If we become aware that we have inadvertently collected personal information from a child under 13 without parental consent, we will take reasonable steps to delete such information from our records as soon as possible. <br />
                        If you believe we may have collected personal information from a child under 13, please contact us at <strong>[iamsikandarali@gmail.com]</strong>.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>7. Data Security</h1>
                    <p className='text-[16px]'>

                        We take reasonable measures to protect the information we collect from unauthorized access, use, or disclosure. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.
                        By using our Services, you acknowledge and accept that we cannot ensure or warrant the security of any information transmitted to or from our Services.

                    </p>
                    <h1 className='text-xl font-semibold py-5'>8. Data Retention</h1>
                    <p className='text-[16px]'>
                        We retain the information collected for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. <br />
                        We may retain certain information in aggregated or de-identified form for analytics and statistical purposes.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>9. Your Choices and Rights</h1>
                    <p className='text-[16px]'>
                        Depending on your location and applicable laws, you may have certain rights regarding your information, such as the right to access, correct, delete, or object to the processing of your information. <br />
                        If you would like to exercise any of these rights, please contact us at <strong>[iamsikandarali@gmail.com]</strong>.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>10. Changes to This Privacy Policy</h1>
                    <p className='text-[16px]'>
                        We may update this Privacy Policy from time to time to reflect changes in our practices, services, or legal requirements. <br />
                        The updated version will be effective as soon as it is accessible, and we encourage you to review this Privacy Policy periodically for any changes. <br />
                        Your continued use of our Services after the effective date of the revised Privacy Policy constitutes your acceptance of the updated terms.
                    </p>
                    <h1 className='text-xl font-semibold py-5'>11. Contact Us</h1>
                    <p className='text-[16px]'>
                        If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at: <br />
                        <strong>
                            Climax Gaming Studios. <br />
                            [Email: iamsikandarali@gmail.com] <br />
                        </strong>
                        By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.
                    </p>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy